

.information-card {
  @extend .d-flex;
  @extend .flex-column;
  @extend .border;
  @extend .shadow;
  background-color: $white;
  border-radius: 3rem;
  @extend .card;
  @extend .h6;
  @extend .p-2;
  @extend .my-2;
  @extend .col-12;
  color: black;

  div {
    @extend .p-2;
    @extend .border-0;

    div {
      border-radius: 3rem;
      @extend .mb-2;
    }
    .blue {
      background-color: $primary;
      color: $white;
    }
    .white {
      background-color: $off-white;
      color: black;
    }
  }

  button {
    @extend .btn-block;
    @extend .mb-2;
  }
}

.information-card-list {
  @extend .d-flex;
  @extend .flex-column;
}

.card-notification-badge {
  @extend .badge;
  @extend .rounded-pill;
  @extend .bg-success;
  @extend .position-absolute;
  @extend .top-0;
  @extend .start-100;
  @extend .translate-middle;
  @extend .me-4;
  @extend .mt-2;
  @extend .fs-6;
}