.standby {
    @extend .d-flex;
    background-color: black;
    position:absolute;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;

    div {
        color: white; /* Fallback for older browsers */
        color: rgba(240, 240, 240, 0.8);

        h1 {
            font-size: 160pt;
        }
    }
}