.button-select {
            @extend .d-flex;
            @extend .flex-row;
            @extend .flex-wrap;
            @extend .align-items-start;

    button {
                @extend .d-flex;
                @extend .m-2;
                @extend .p-2;
                background-color: $white;
                border-radius: 3rem;
                @extend .border-0;
    }

    .selected {
                @extend .btn-primary;
    }
}