.multiSpot {
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-fill;
    @extend .m-0;
    @extend .p-0;

    &-large {
        @extend .d-flex;
        @extend .flex-row;

        span {
            @extend .d-flex;
            @extend .flex-column;

            .select {
                @extend .d-flex;
                @extend .flex-row;
                @extend .flex-wrap;
                @extend .align-items-start;
                @extend .m-2;
                @extend .p-2;
                background-color: $white;
                border-radius: 3rem;

                div{
                    @extend .d-flex;
                    @extend .flex-row;
                    @extend .justify-content-between;
                    @extend .align-items-center;
                    @extend .title;
                    @extend .h5;
                    @extend .w-100;

                    p {
                        @extend .w-100;
                    }
                    button {
                        @extend .m-0;
                        @extend .w-100;
                        @extend .btn-light;
                        @extend .text-dark;
                    }
                }

                button {
                    @extend .clickable;
                    @extend .m-1;
                    @extend .btn;
                    @extend .btn-primary;
                    @extend .text-light;
                    width: 5rem;
                    height: 5rem;
                    border-radius: 2rem;

                }
                .selected {
                    @extend .btn-warning;
                }
                .disabled {
                    @extend .btn-light;
                    @extend .text-dark;
                }
                .saved-row {
                    @extend .btn-info;
                }
            }
        }
    }

    div {
        @extend .d-flex;
        @extend .flex-column;
        @extend .pt-2;

        button {
            @extend .mt-1;
        }
    }
    .multiSpotRow {
        @extend .d-flex;
        @extend .flex-row;
        @extend .m-0;
        @extend .p-0;
        @extend .flex-fill;
    
        .slot {
            @extend .d-flex;
            @extend .flex-fill;
            @extend .align-items-center;
            @extend .justify-content-center;
            @extend .clickable;
            @extend .btn;
            @extend .text-light;
    
            &-highlighted {
                @extend .clickable;
                @extend .btn-warning;
            }
    
            &-normal {
                @extend .btn-primary;
            }
        }
    }
}


