.navbar-brand {
  width: 30%;
}

.navbar-button-group {
  @extend .btn-group;

  button + button {
    @extend .border-start;
  }

}

.navbar-button {
  @extend .btn;
  @extend .text-center;
  border-radius: 3rem;
  @extend .border-0;
  @extend .bg-white;
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
}

.language-picker {
  @extend .m-3;
  @extend .mb-3;
  @extend .border-0;
  border-radius: 3rem;
  background: $white;
  @extend .py-2;

  button {
    @extend .btn;
    @extend .btn-block;
    @extend .text-start;
    @extend .clickable;
  }

  button + button {
    @extend .border-top;
  }

}
.flag {
  @extend .border;
  @extend .border-0;
  @extend .m-2;
}