
.btn {
  @extend .btn;
  @extend .clickable;
  border-radius: 3rem;
}

.btn-block {
  @extend .col-12;
}

.primary-button {
    @extend .btn;
    @extend .btn-outline-light;
    @extend .btn-primary;
    @extend .clickable;
    @extend .border-0;

    &.disabled {
      background-color: $primary;
    }

    &.active, &.focus {
    background-color: $primary;
    border-color: $secondary;
    }
}

.secondary-button {
  @extend .primary-button;
  @extend .btn-secondary;
  @extend .btn-outline-light;

    &.disabled {
      background-color: $secondary;
    }

    &.active, &.focus {
      background-color: $secondary;
      border-color: $info;
    }
}

.secondary-button-outline {
  @extend .btn;
  @extend .clickable;
  @extend .btn-light;
  @extend .btn-outline-secondary;
}
  
.danger-button {
  @extend .primary-button;
  @extend .btn-danger;
  @extend .btn-outline-light;
}

.danger-button-outline {
  @extend .btn;
  @extend .clickable;
  @extend .btn-light;
  @extend .btn-outline-danger;
}
  
.danger-button:disabled{
  background-color: $danger;
}
.danger-button:active, .danger-button:active:focus {
  background-color: $danger;
  border-color: $orange;
}
  
.warning-button {
  @extend .primary-button;
  @extend .btn-warning;
  @extend .btn-outline-light;
}

.warning-button-outline {
  @extend .btn;
  @extend .clickable;
  @extend .btn-light;
  @extend .btn-outline-warning;
}
  
.warning-button:disabled{
  background-color: $warning;
}
.warning-button:active, .warning-button:active:focus {
  background-color: $warning;
  border-color: $yellow;
}
  
.light-button {
  @extend .btn;
  @extend .clickable;
  @extend .btn-light;
  @extend .btn-outline-secondary;
}
  
.light-button:disabled{
  background-color: $light;
}
.light-button:active, .light-button:active:focus {
  background-color: $light;
  border-color: $cyan;
}
  
.success-button {
  @extend .primary-button;
  @extend .btn-success;
  @extend .btn-outline-light;
}

.success-button-outline {
  @extend .btn;
  @extend .clickable;
  @extend .btn-light;
  @extend .btn-outline-success;
}
  
.success-button:disabled{
  background-color: $success;
}
.success-button:active, .success-button:active:focus {
  background-color: $success;
  border-color: $teal;
}

.success-button, .warning-button, .primary-button, .danger-button, .secondary-button, .light-button {
  &-small {
    @extend .w-25;
  }
}

.btn-sender {
  @extend .btn;
  @extend .btn-lg;
  @extend .btn-block;
  @extend .clickable;
  min-height: 12rem;
  img {
    height: 8rem;
  }
}
