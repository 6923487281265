
.shop-filter {
    background-color: white;
    @extend .d-flex;
    @extend .flex-column;
    @extend .border-0;
    @extend .m-0;
    @extend .p-0;
    @extend .h5;
    @extend .shadow;
    border-radius: 3rem;
    overflow: hidden;
  }
  
  
  .shop-header {
    background-color: white;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .border-0;
    @extend .m-0;
    @extend .p-0;
  
    button {
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .w-100;
      @extend .border-0;
      @extend .p-3;
      @extend .clickable;
      @extend .m-0;
      background-color: white;
  
    }
    button.selected {
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .w-100;
      @extend .border-0;
      @extend .p-3;
      @extend .clickable;
      @extend .m-0;
      color: white;
      background-color: $primary;
    }
  }
  
  .shop-product-list {
    @extend .d-flex;
    @extend .flex-row;
    @extend .flex-wrap;
    @extend .justify-content-center;
    @extend .m-0;
    @extend .mt-2;
    @extend .truncate;
  
    div {
      @extend .d-flex;
      @extend .flex-column;
      @extend .align-items-center;
      @extend .justify-content-center;
      @extend .h4;
      @extend .me-3;
      @extend .my-2;
      @extend .col-5;
      @extend .clickable;
      @extend .shadow;
      @extend .text-wrap;
      @extend .p-3;
      @extend .truncate;
      color: $primary;
      background-color: $white;
      border-radius: 3rem;
  
      img {
        @extend .m-1;
        max-width: 100%;
        height: auto;
        object-fit: contain;
      }
  
      p {
        @extend .pt-2;
        @extend .truncate;
      }
    }

    div:nth-child(2n) {
      @extend .ms-3;
      @extend .me-0;
    }
  }
  
  .shop-attr-filter {
    @extend .d-flex;
    @extend .flex-column;
    overflow: hidden;
    border-radius: 3rem;
    background-color: white;
    @extend .p-3;
  
    p {
      @extend .title;
      @extend .h4;
    }
  
    div {
      @extend .d-flex;
      @extend .flex-column;
      overflow: hidden;
      border-radius: 3rem;
      background-color: white;
      @extend .p-3;
      
      .shop-attr-value-container {
        @extend .d-flex;
        @extend .flex-row;
        @extend .flex-wrap;
      
        div {
          @extend .p-2;
          @extend .px-3;
          @extend .m-1;
          border-radius: 3rem;
          background-color: $off-white;
          @extend .clickable;
      
        }
        .selected {
          color: $white;
          background-color: $primary;
        }
        .disabled {
          opacity: 0.3;
        }
      }
    }
  }

.product_image {
  object-fit: scale-down;
  height: 25%;
}