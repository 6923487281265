div.spot-layout {
  width: 100%;
  > div {
    position: relative;
    > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  > div:after {
    content: '';
    display: block;
    padding-top: var(--aspect-ratio);
  }
}

div.spot-layout-restricted {
  width: 100%;
  max-width: 640px;
  > div {
    position: relative;
    > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  > div:after {
    content: '';
    display: block;
    padding-top: var(--aspect-ratio);
  }
}

.spotlayout-container {
  overflow: scroll;
}

.spotlayout {
  transform-origin: top left;
  max-width: 100%;
  @extend .position-relative;
  @extend .ms-auto;
  @extend .me-auto;
}

.spotlayoutitem-terminal {
  @extend .bg-light;
  @extend .small-border;
  opacity: 0.9;
}

.spotlayoutitem-selectable {
    @extend .small-border;
    @extend .clickable;
    transition: .5s;
    color: black;
    background: linear-gradient(-45deg, $gray-pastel-500, $gray-pastel-600);
    opacity: 0.8;
}
  
.spotlayoutitem-highlighted {
    @extend .spotlayoutitem-selectable;
    background: $primary;
    color: white;
    opacity: 0.8;
}
.spotlayoutitem-marked {
    @extend .spotlayoutitem-highlighted;
    @extend .border-0;
    z-index: 2;
    box-shadow: 8px 8px 8px rgb(0, 0, 0);
    transform:scale(1.05);
}

.spotlayoutitem-label {
  @extend .h-100;
  @extend .text-center;
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-center;
  @extend .align-items-center;
  font-size: 1rem;
}

.spotlayoutitem-unavailable {
  @extend .small-border;
  color: black;
  background: linear-gradient(-45deg, $gray-pastel-500, $gray-pastel-600);
  opacity: 0.8;
}

.slot {
  border: 1px solid black;
  background-color: $primary;
}