.list-filter-input {
    @extend .input-group;
    @extend .mb-3;
}

.default-list {
    @extend .card;
    @extend .d-flex;
    @extend .shadow;
    @extend .border-0;
    @extend .w-100;

    div {
        table {
            @extend .table;
            @extend .table-light;
            @extend .table-borderless;
            @extend .table-sm;
            @extend .table-responsive;

            thead {
                background-color: $primary;
                tr {
                    background-color: $primary;
                    th {
                        @extend .py-3;
                        @extend .ps-1;
                        @extend .table-dark;
                        background-color: $primary;

                        position: sticky;
                        top: 0;
                    }
                }
            }
        }
    }
}