.clickable {
    position: relative;
    overflow: hidden;
    transition: background-color .3s;
  }
  
  .clickable > * {
    position: relative;
  }
  
  .clickable:before {
    content: "";
    
    position: absolute;
    top: 50%;
    left: 50%;
    
    display: block;
    width: 0;
    padding-top: 0;
    border-radius: 100%;
    background-color: rgba(184, 184, 184, 0.2);
    
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .clickable:active:before {
    width: 200%;
    padding-top: 200%;
    
    transition: width .3s ease-out, padding-top .3s ease-out;
  }