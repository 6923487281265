@import url('https://fonts.googleapis.com/css?family=Montserrat:300');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400');
@import url('https://fonts.googleapis.com/css?family=Montserrat:500');
@import url('https://fonts.googleapis.com/css?family=Montserrat:600');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:800');
@import url('https://fonts.googleapis.com/css?family=Montserrat:900');

$cyan: #41b9c1;  // Telloport Cyan
$tello-dark: #12354b; // Telloport dark color

$tello-light-pastel: #0DA3BA;
$tello-dark-pastel: #046E94;

// Derived from theme colors above using https://stackoverflow.com/questions/42966641/how-to-transform-black-into-any-given-color-using-only-css-filters/43960991#43960991
$cyan-filter: invert(61%) sepia(38%) saturate(555%) hue-rotate(135deg) brightness(94%) contrast(105%);
$tello-dark-filter: invert(20%) sepia(29%) saturate(964%) hue-rotate(159deg) brightness(89%) contrast(102%);

// Color system from bootstrap
$off-white: #eae6ea;
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-pastel-500: #A8A9AD !default;
$gray-pastel-600: #838487 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #f24933 !default;
$orange:  #fc9712 !default;
$yellow:  #ffc107 !default;
$green:   #26cd41 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$blue-pastel:    #5DADE2 !default;
$red-pastel:     #C70039 !default;
$yellow-pastel:  #FFC300 !default;
$green-pastel:   #2ECC71 !default;
$dark-green-pastel: #1F7D1F;
$light-green-pastel: #5DC360;


// Customized themes
$primary:       $tello-light-pastel !default;
$secondary:     #5DADE2 !default;
$success:       $green !default;
$info:          #b5e0fd !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$link-color:    $secondary;

$default-gradient: linear-gradient(45deg, $secondary, $primary) !default;

$enable-shadows:                              true !default;
$enable-gradients:                            true !default;
$enable-responsive-font-sizes:                true !default;

// Size tweaks for large displays
$spacer:         2rem !default;
$font-size-base: 2rem !default;
$grid-gutter-width:           60px !default;
$border-width:                2px !default;
$border-radius:               .5rem !default;
$border-radius-lg:            .6rem !default;
$border-radius-sm:            .4rem !default;
$list-inline-padding:         1rem !default;
$table-cell-padding:          1.5rem !default;
$table-cell-padding-sm:       .6rem !default;
$input-btn-padding-y:         .75rem !default;
$input-btn-padding-x:         1.5rem !default;
$input-btn-focus-width:       .4rem !default;
$input-btn-padding-y-sm:      .5rem !default;
$input-btn-padding-x-sm:      1rem !default;
$input-btn-padding-y-lg:      1rem !default;
$input-btn-padding-x-lg:      2rem !default;
$btn-block-spacing-y:         1rem !default;
$label-margin-bottom:         1rem !default;
$form-text-margin-top:        .5rem !default;
$form-check-input-gutter:     2.5rem !default;
$form-check-input-margin-y:   .6rem !default;
$form-check-input-margin-x:   .5rem !default;
$form-check-inline-margin-x:       1.5rem !default;
$form-check-inline-input-margin-x: .625rem !default;
$form-grid-gutter-width:           20px !default;
$form-group-margin-bottom:         2rem !default;
$custom-control-gutter:            1rem !default;
$custom-control-spacer-x:          2rem !default;
$custom-control-indicator-size:    2rem !default;
$nav-link-padding-y:                1rem !default;
$nav-link-padding-x:                2rem !default;
$navbar-nav-link-padding-x:         1rem !default;
$navbar-toggler-padding-y:          .5rem !default;
$navbar-toggler-padding-x:          1.5rem !default;

@import "~bootstrap/scss/bootstrap";
@import "scss/button";
@import "scss/modal";
@import "scss/pin";
@import "scss/clickable";
@import "scss/action-card";
@import "scss/spotlayout";
@import "scss/navbar";
@import "scss/card";
@import "scss/multiSpotLayout";
@import "scss/shop";
@import "scss/form";
@import "scss/list";
@import "scss/select";
@import "scss/standby";
@import "scss/footer";


p {
  @include font-size(2rem);
}
h6 {
  @include font-size(2rem);
}
h5 {
  @include font-size(2.5rem);
}
h4 {
  @include font-size(3rem);
}
h3 {
  @include font-size(3.5rem);
}
h2 {
  @include font-size(4rem);
}
h1 {
  @include font-size(4.5rem);
}

.title {
  @extend .h4;
}

.sub-title {
  @extend .title;
  @extend .h5;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  //text-overflow: ellipsis;
}

.rounded-action {
  border-bottom-right-radius: 2rem !important;
  border-top-left-radius: 2rem !important;
}

.border-2 {
  border-style: solid;
  border-width: 2px;
}

.square-flex {
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: auto;
  height: auto;
}

.square-flex:before {
  content: "";
  display: inline-block;
  padding-top: 100%;
  vertical-align: middle;
}

.mw-48 {
  max-width: 48px;
  flex-grow: 1;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 2 !important;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.small-border {
  @extend .border;
  @extend .border-white;
  @extend .border-1;
  @extend .rounded-1;
}

.spinner-border-lg {
  @extend .spinner-border;
  max-width: 6rem;
  max-height: 6rem;
}

.filter-primary {
  filter: $tello-dark-filter !important;
}

.filter-secondary {
  filter: $cyan-filter !important;
}

.custom-control-lg > .custom-control-label::before,
.custom-control-lg > .custom-control-label::after {
  top: ($font-size-base * $line-height-base - $custom-control-indicator-size) * 1.25 / 2;
  width: $custom-control-indicator-size * 1.25;
  height: $custom-control-indicator-size * 1.25;
}

.img-fluid-legacy {
  @extend .img-fluid;
  object-fit: contain;
}

.dashboard-scan-image {
  object-fit: contain;
  max-width: 100%;
  max-height: 40rem;

  @include media-breakpoint-up(xl) {
    height: 20rem;
  }

  @include media-breakpoint-between(xs, lg) {
    height: 40rem;
  }
}

.responsive-resize-img {
  @include media-breakpoint-up(xl) {
    height: 18rem;
  }

  @include media-breakpoint-between(xs, lg) {
    height: 40rem;
  }
}

.btn-scaling-lg {
  @include media-breakpoint-between(xs, lg) {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
  }
}

body {
  font-family: Montserrat, sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: $off-white;
  width: 100%;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}

