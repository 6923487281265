.footer {
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;

    div {
        @extend .d-flex;
        @extend .align-self-center;
        @extend .small;
        @extend .flex-row;
        @extend .align-items-center;
        @extend .mb-1;
    }
}

.footer-telloport-logo {
  width: 10rem;
}

.footer-spot-name {
  font-size: 1.2rem;
}