.text-input {
    @extend .form-control;
    @extend .mb-2;
}

label {
        display: flex;
        align-items: center;
    }

label.required {
    &:after {
        margin-left: 0.5rem;
        content: ' ';
        background-color: $danger;
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 1rem;
    }
}

.form-error {
    color: $red;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
    display: block;
    padding: 0.5rem 0 1rem 0;
}