

.dashboard-action {
    @extend .d-flex;
    @extend .flex-column;
    @extend .btn;
    @extend .btn-block;
    @extend .text-center;
    border-radius: 3rem;
    @extend .border-0;
    @extend .align-items-stretch;
    @extend .p-0;
    @extend .mt-2;
    @extend .position-relative;
    @extend .shadow;
    height: 100%;
    overflow: visible;
  
    .dashboard-action-footer {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
      height: 100%;
      background: $primary;
      @extend .border-0;
      border-bottom-left-radius: 3rem;
      border-bottom-right-radius: 3rem;


      &-primary {
        background-color: $primary;
      }
      &-danger {
        background-color: $danger;
      }
      &-secondary {
        background-color: $secondary;
      }
      &-success {
        background-color: $success;
      }
      &-warning {
        background-color: $warning;
      }
      &-light {
        background-color: $white;
      }
    }
    
    &-primary {
      background-color: $primary;
    }
    &-danger {
      background-color: $danger;
    }
    &-secondary {
      background-color: $secondary;
    }
    &-success {
      background-color: $success;
    }
    &-warning {
      background-color: $warning;
    }
    &-light {
      background-color: $white;
    }
}

.dashboard-action-icon {
  font-size: 11rem;
}

.action-card-notification-badge {
  @extend .badge;
  @extend .rounded-pill;
  @extend .bg-success;
  @extend .position-absolute;
  @extend .top-0;
  @extend .start-100;
  @extend .translate-middle;
  @extend .me-4;
  @extend .mt-2;
  @extend .fs-6;
}

