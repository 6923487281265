.modal-backdrop.show {
    opacity: 0;
}
  
.modal-content {
    @extend .shadow;
    @extend .shadow-lg;
    @extend .border-1;
    border-radius: 3rem;
    background: $white;
  
}

.modal-footer {
    @extend .d-flex;
    @extend .flex-row;
    @extend .justify-content-center;
    @extend .border-0;
    @extend .mt-0;
    @extend .pt-0;
    @extend .pb-1;
}
  
.modal-body {
    @extend .h6;
    @extend .p-2;
    @extend .mb-0;
  
    &-element {
      @extend .h6;
      @extend .m-0;
      @extend .border-0;
      border-radius: 3rem;
      background: $white;
      @extend .p-4;
      overflow:hidden;
  
      li {
        list-style-type: none;
      }
  
      li + li{
        @extend .mt-3;
      }
    }
}

.scanner-help-message {
  @extend .m-3;
  @extend .mb-5;
  left:0;
  bottom:0;
  right:0;
  z-index: 3;
  position: fixed;
  
}

.scanner-help-message-backdrop { 
  backdrop-filter: blur(8px);
  width: 100%;
  height: 100%;
  left:0;
  bottom:0;
  right:0;
  position: absolute;
}
.scanner-help-message-icon {
  @extend .text-light;
  font-size: 11rem;
}